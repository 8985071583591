<template>
  <full-page :options="options" ref="fullpage">
    <div class="section" data-menuanchor="one">
      <div class="banner_box">
        <img class="video" src="@/assets/imgs/banner.png" alt="">
        <p>Ragana<br/>健身教练的收入放大器</p>
      </div>
    </div>
    <div class="section" data-menuanchor="two">
      <div class="section_box flex flex-between">
        <div class="section_left_box flex flex-column flex-left flex-center">
          <p>海量订单</p>
          <span>垂直精准客源，订单实时更新<br/>助力收入极速增长</span>
        </div>
        <img src="@/assets/imgs/img6.png" alt="">
      </div>
    </div>
    <div class="section" data-menuanchor="three">
      <div class="section_box flex flex-between">
        <div class="section_left_box flex flex-column flex-left flex-center">
          <p>收益透明</p>
          <span>多种收益来源，数据清晰安全<br/>专注服务省心无忧</span>
        </div>
        <img src="@/assets/imgs/img7.png" alt="">
      </div>
    </div>
    <div class="section" data-menuanchor="four">
      <div class="section_box flex flex-between">
        <div class="section_left_box flex flex-column flex-left flex-center">
          <p>时间灵活</p>
          <span>打破传统获客渠道，随时随地接单抢单<br/>自由分配个人时间</span>
        </div>
        <img src="@/assets/imgs/img8.png" alt="">
      </div>
    </div>
    <div class="section" data-menuanchor="five">
      <div class="section_box flex flex-between">
        <div class="section_left_box flex flex-column flex-left flex-center">
          <p>提现秒到</p>
          <span>极速提现，无忧到账<br/>便捷安全，专业可靠</span>
        </div>
        <img src="@/assets/imgs/img9.png" alt="">
      </div>
    </div>
    <FooterVue class="section section1" data-menuanchor="six"></FooterVue>
  </full-page>

  <RightCode v-show="show" :imgs="require('@/assets/imgs/rightCode1.png')"></RightCode>
</template>

<script setup>
import { reactive, ref, defineEmits,watch,onBeforeUnmount } from 'vue';
import FooterVue from '../components/FooterVue.vue';
import RightCode from '../components/RightCode.vue';
const show=ref(true);
const fullpage = ref();
const changeColor = defineEmits(['changeColor','cNames']);

// watch(()=>window.location.href,(newVal, oldVal)=>{
//   console.log(newVal, oldVal,32323)
// })
onBeforeUnmount(()=>{
  changeColor('cNames','')
})

const options = {
  licenseKey: "OPEN-SOURCE-GPLV3-LICENSE",
  anchors: ['one', 'two', 'three', 'four', 'five','six'],
  // menu: '#menu',
  lockAnchors: true,
  navigation:false,//显示导航
  navigationPosition:'right',
  verticalCentered: false,
  scrollOverflow: false,
  onLeave: (index, nextIndex)=>{
    if(nextIndex.index==5){
      show.value=false
    }else{
      show.value=true
    }
    if(nextIndex.index === 0){
      changeColor('changeColor', '#fff')
      changeColor('cNames','')
    }else{
      changeColor('changeColor', '#000')
      changeColor('cNames','nav_box3')
    }
  },
  afterRender:()=>{
    setTimeout(()=>{
      console.log(window.location.href.split('?').length)
      if(window.location.href.split('?').length==1){
        fullpage.value.api.moveTo(1,0);
      }else{
        var href=Number(window.location.href.split('?')[1])+1
        console.log(href,fullpage.value.api)
        fullpage.value.api.moveTo(href,0);
      }
    },200)
  }
}
</script>

<style lang="scss" scoped>
.fp-overflow{
  height: 100%;
}
.one-footer{
  position: inherit;
}
.banner_box {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;

  .video {
    width: 100%;
    height: auto;
  }

  p {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    font-size: 88px;
    font-family: SourceBold;
    font-weight: bold;
    color: #FFFFFF;
    // width: 500px;
    height: 284px;
    text-align: center;
  }
}
.section{
  background: url(@/assets/imgs/bg3.png) no-repeat center;
  background-size: 100%;
  background-color: #fff;
  position: relative;
  &:nth-child(2n+1){
    background: url(@/assets/imgs/bg4.png) no-repeat center;
    background-size: 100%;
    background-color: #FEF7F7;
    .section_box{
      flex-direction: row-reverse;
      img{
        margin-left: 180px;
        margin-right: 0;
      }
      .section_left_box{
        align-items: flex-end;
        margin-right: 180px;
        margin-left: 0;
        span{
          text-align: right;
        }
      }
    }
  }
  &.section1{
    background: none;
  }
  .section_box{
    overflow: hidden;
    height: 100%;
    .section_left_box{
      height: 100vh;
      margin-left: 180px;
      p{
        font-size: 60px;
        font-family: SourceBold;
        font-weight: bold;
        color: #000000;
        margin-top: 0;
        margin-bottom: 30px;
      }
      span{
        font-size: 24px;
        font-family: SourceNormal;
        font-weight: 400;
        color: #666666;
      }
    }
    img{
      width: 38%;
      height: 68.8%;
      margin-right: 180px;
    }
  }
}
.fp-right,.fp-watermark{
  display: none;
}
</style>