<template>
  <full-page :options="options" ref="fullpage">
    <div class="section" data-menuanchor="one">
      <div class="section_box flex flex-between">
        <div class="section_left_box flex flex-column flex-left flex-center">
          <p>高效便捷，上门私教</p>
          <span>无需出门，多样课程立即定制<br/>在家就能享受健身房待遇</span>
        </div>
        <img src="@/assets/imgs/img1.png" alt="">
      </div>
    </div>
    <div class="section" data-menuanchor="two">
      <div class="section_box flex flex-between">
        <div class="section_left_box flex flex-column flex-left flex-center">
          <p>随心所练，自由私教</p>
          <span>拒绝健身卡“道德绑架”，自由选择场地和教练<br/>随时随地都能练</span>
        </div>
        <img src="@/assets/imgs/img2.png" alt="">
      </div>
    </div>
    <div class="section" data-menuanchor="three">
      <div class="section_box flex flex-between">
        <div class="section_left_box flex flex-column flex-left flex-center">
          <p>氛围触达，精品团课</p>
          <span>用音乐催化汗水，和志同道合的伙伴<br/>享受每一次呼吸下的坚持</span>
        </div>
        <img src="@/assets/imgs/img3.png" alt="">
      </div>
    </div>
    <div class="section" data-menuanchor="four">
      <div class="section_box flex flex-between">
        <div class="section_left_box flex flex-column flex-left flex-center">
          <p>一键连麦，线上私教</p>
          <span>社恐专属，一对一直播连麦，让私教<br/>成为你的健身搭子</span>
        </div>
        <img src="@/assets/imgs/img4.png" alt="">
      </div>
    </div>
    <div class="section" data-menuanchor="five">
      <div class="section_box flex flex-between">
        <div class="section_left_box flex flex-column flex-left flex-center">
          <p>跟练跟买，直播互动</p>
          <span>告别智商税，消费不踩坑，直播间专业教练<br/>教你怎么练，带你怎么买</span>
        </div>
        <img src="@/assets/imgs/img5.png" alt="">
      </div>
    </div>
    <FooterVue class="section section1" data-menuanchor="six"></FooterVue>
  </full-page>

  <RightCode v-show="show"></RightCode>
</template>

<script setup>
import { reactive, ref, defineEmits,onMounted,watch } from 'vue';
import FooterVue from '../components/FooterVue.vue';
import RightCode from '../components/RightCode.vue';
import { useRouter } from 'vue-router'
const router = useRouter()
const show=ref(true);
const fullpage = ref();
const changeColor = defineEmits(['changeColor']);

const options = {
  // licenseKey: "OPEN-SOURCE-GPLV3-LICENSE",
  licenseKey: "OPEN-SOURCE-GPLV3-LICENSE",
  anchors: ['one', 'two', 'three', 'four', 'five','six'],
  // menu: '.section',
  lockAnchors: true,
  navigation:false,//显示导航
  navigationPosition:'right',
  verticalCentered: false,
  scrollOverflow: false,
  onLeave: (index, nextIndex)=>{
    if(nextIndex.index==5){
      show.value=false
    }else{
      show.value=true
    }
    if(nextIndex.index === 0){
      changeColor('changeColor', '#fff')
    }else{
      changeColor('changeColor', '#000')
    }
  },
  afterRender:()=>{
    setTimeout(()=>{
      var href=window.location.href.split('?')[1]
      console.log(href,fullpage.value.api)
      fullpage.value.api.moveTo(href,0);
    },200)
  }
}

</script>

<style lang="scss" scoped>
.fp-overflow{
  height: 100%;
}
.one-footer{
  position: inherit;
}
.section{
  background: url(@/assets/imgs/bg1.png) no-repeat center;
  background-size: 100%;
  background-color: #fff;
  position: relative;
  &:nth-child(2n){
    background: url(@/assets/imgs/bg2.png) no-repeat center;
    background-size: 100%;
    background-color: #FDF8F8;
    .section_box{
      flex-direction: row-reverse;
      img{
        margin-left: 24px;
        margin-right: 0;
      }
      .section_left_box{
        align-items: flex-end;
        margin-right: 180px;
        margin-left: 0;
        span{
          text-align: right;
        }
      }
    }
  }
  &.section1{
    background: none;
  }
  .section_box{
    align-items: end;
    overflow: hidden;
    height: 100%;
    .section_left_box{
      height: 100vh;
      margin-left: 180px;
      p{
        font-size: 60px;
        font-family: SourceBold;
        font-weight: bold;
        color: #000000;
        margin-top: 0;
        margin-bottom: 30px;
      }
      span{
        font-size: 24px;
        font-family: SourceNormal;
        font-weight: 400;
        color: #666666;
      }
    }
    img{
      width: 44%;
      height: 94.4%;
      margin-right: 24px;
    }
  }
}
.fp-right,.fp-watermark{
  display: none;
}
</style>