<template>
  <div>
    <div class="banner_box">
      <!-- <video id="myVideo" src="//flv.cndns.com/cn/webnew/images/cndns-banner-video-2.mp4" width="100%" autoplay="autoplay" loop="loop" muted="muted" class="video">您的浏览器不支持 video 标签。</video> -->
      <img src="@/assets/imgs/video.png" alt="">
      <img class="slogan" src="@/assets/imgs/slogan.png" alt="">
    </div>
    <!-- 开启健康运动生活 -->
    <div class="demo_box">
      <p>开启健康运动生活</p>
      <span>科学私教指导带来优质用户体验，多样社区内容营造积极健身氛围，每一项功能<br />都为健身爱好者量身打造，致力于为用户带来健康品质生活！</span>
      <div class="demo_con flex flex-wrap flex-between">
        <img :src="item" v-for="(item, index) in demos" :key="index" alt="">
      </div>
    </div>
  </div>
  <FooterVue class="section"></FooterVue>

  <RightCode v-show="show"></RightCode>
</template>

<script>
import FooterVue from '../components/FooterVue.vue';
import RightCode from '../components/RightCode.vue';
export default {
  components:{
    FooterVue,RightCode
  },
  data() {
    return {
      demos: [require('@/assets/imgs/demo.png'), require('@/assets/imgs/demo.png'), require('@/assets/imgs/demo.png'), require('@/assets/imgs/demo.png'), require('@/assets/imgs/demo.png'), require('@/assets/imgs/demo.png'), require('@/assets/imgs/demo.png'), require('@/assets/imgs/demo.png'), require('@/assets/imgs/demo.png'), require('@/assets/imgs/demo.png')],
      show:false
    }
  },
  mounted(){
    window.addEventListener("scroll", this.scrolling);
  },
  methods: {
    scrolling() {
      // 滚动条距文档顶部的距离
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

      if (scrollTop > 2200) {
        this.show = false
      } else {
        this.show = true
      }
    }
  }
 
}

</script>

<style lang="scss" scoped>
.banner_box {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;

  .video {
    width: 100%;
    height: auto;
  }

  .slogan {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
}

.demo_box {
  margin-top: 124px;
  margin-bottom: 100px;

  p {
    font-size: 70px;
    font-family: SourceBold;
    font-weight: bold;
    color: #000000;
    text-align: center;
    margin-top: 0;
    margin-bottom: 56px;
  }

  span {
    font-size: 24px;
    font-family: SourceNormal;
    color: #666666;
    text-align: center;
    display: block;
  }

  .demo_con {
    // margin-top: 48px;
    padding-bottom: 20px;
    overflow: hidden;

    img {
      width: 20%;
      &:nth-child(1),
      &:nth-child(6) {
        margin-left: -54px;
      }

      &:nth-child(5),
      &:nth-child(10) {
        margin-right: -54px;
      }

      &:nth-child(1),
      &:nth-child(3),
      &:nth-child(5) {
        margin-top: 110px;
      }

      &:nth-child(6),
      &:nth-child(8),
      &:nth-child(10) {
        margin-top: 62px;
      }

      &:nth-child(2),
      &:nth-child(4),
      &:nth-child(7),
      &:nth-child(9) {
        margin-top: -42px;
        -webkit-animation: fadeInDown .8s ease-in-out forwards, slideUpDown 6s linear .8s infinite;
        animation: fadeInDown .8s ease-in-out forwards, slideUpDown 6s linear .8s infinite
      }

      &:nth-child(1),
      &:nth-child(3),
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(8),
      &:nth-child(10) {
        -webkit-animation: fadeInUp .8s ease-in-out forwards, slideDownUp 6s linear .8s infinite;
        animation: fadeInUp .8s ease-in-out forwards, slideDownUp 6s linear .8s infinite
      }
    }
  }
}


@-webkit-keyframes slideUpDown {

  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }

  50% {
    -webkit-transform: translate3d(0, -1%, 0);
    transform: translate3d(0, -1%, 0)
  }
}

@keyframes slideUpDown {

  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }

  50% {
    -webkit-transform: translate3d(0, -1%, 0);
    transform: translate3d(0, -1%, 0)
  }
}

@-webkit-keyframes slideDownUp {

  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }

  50% {
    -webkit-transform: translate3d(0, 1%, 0);
    transform: translate3d(0, 1%, 0)
  }
}

@keyframes slideDownUp {

  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }

  50% {
    -webkit-transform: translate3d(0, 1%, 0);
    transform: translate3d(0, 1%, 0)
  }
}</style>