<template>
  <div>
    <div class="nav_box1" :class="[navClass,cNames]">
      <div class="nav_con flex flex-between">
        <img src="@/assets/imgs/logo2.png" alt="">
        <!-- <router-link to="/" active-class="active"></router-link> -->
        <el-tabs v-model="activeName">
          <el-tab-pane name="home">
            <template #label>
              <router-link to="/" class="nav_right_item" active-class="active">首页</router-link>
            </template>
          </el-tab-pane>
          <el-tab-pane label="产品介绍" name="productInfo">
            <template #label>
              <router-link to="/productInfo" class="nav_right_item" active-class="active">产品介绍</router-link>
            </template>
          </el-tab-pane>
          <el-tab-pane label="教练入驻" name="CoachInfo">
            <template #label>
              <router-link to="/CoachInfo" class="nav_right_item" active-class="active">教练入驻</router-link>
            </template>
          </el-tab-pane>
          <el-tab-pane label="健身房入驻" name="GymInfo">
            <template #label>
              <router-link to="/GymInfo" class="nav_right_item" active-class="active">健身房入驻</router-link>
            </template>
          </el-tab-pane>
          <el-tab-pane label="关于我们" name="AboutInfo">
            <template #label>
              <router-link to="/AboutInfo" class="nav_right_item" active-class="active">关于我们</router-link>
            </template>
          </el-tab-pane>
        </el-tabs>
        <div class="nav_right_box">
          <a href="https://anchor.wanweifit.com/#/login" class="nav_right_item" target="_blank">直播管理</a>
          <a href="https://gym.wanweifit.com/#/login" class="nav_right_item" target="_blank">健身房管理</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:['cNames'],
  data() {
    return {
      activeName: 'home',
      navClass: '',
    }
  },
  mounted() {
    window.addEventListener("scroll", this.scrolling);
  },
  watch: {
    $route(to, from) {
      // console.log(to, from)
      if (to.path == '/') {
        this.activeName = 'home'
      } else {
        this.activeName = to.name
      }
      if (to.path == '/productInfo') {
        this.navClass = 'nav_box3'
      } else if(to.path == '/AboutInfo'){
        this.navClass = 'nav_box3'
      } else {
        this.navClass = ''
      }
    }
  },
  methods: {
    scrolling() {
      // 滚动条距文档顶部的距离
      if(this.activeName=='home'){
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        if (scrollTop < 80) {
          this.navClass = ''
        }
        if (scrollTop > 80) {
          this.navClass = 'nav_box2'
        }
        if (scrollTop > (window.screen.height - 240)) {
          this.navClass = 'nav_box3'
        }
      }
    },
    // handleClick(tab, event) {
    //   console.log(tab.index, event,this.activeName)
    //   if(tab.index==0){
    //     this.$router.push({ path: '/productInfo' })
    //   }
    // }
  }
}
</script>

<style lang="scss">
.nav_box1 {
  width: 100%;
  height: 80px;
  background: transparent;
  margin: 0 auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

  &.nav_box2 {
    background: rgba(0, 0, 0, 0.2);
    box-shadow: 0px 6 10px 0px rgba(0, 0, 0, 0.06);
  }

  &.nav_box3 {
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 6 10px 0px rgba(229, 10, 51, 0.06);
    &.nav_box4{
      background: transparent;
    }

    .nav_con {
      .el-tabs__item {
        .nav_right_item {
          color: #666;
        }
      }
      .nav_right_box {
        .nav_right_item {
          color: #666;
          border-color: #666;
        }
      }
    }
  }

  .nav_con {
    width: 81%;
    height: 100%;
    margin: 0 auto;

    .el-tabs__header {
      margin: 0;
      height: 100%;
    }

    .el-tabs__content {
      display: none;
    }

    .el-tabs__nav-wrap::after {
      display: none;
    }

    .el-tabs__item {
      padding: 0 35px;
      height: 80px;
      line-height: 80px;

      .nav_right_item {
        color: #fff;
        font-size: 18px;
        font-family: SourceRegular;
        font-weight: 400;
        width: 100%;
        height: 100%;
        display: block;
      }

      &.is-active .nav_right_item {
        color: #E50A33;
      }

      &:nth-child(2) {
        padding: 0 15px;

        .nav_right_item {
          padding: 0 20px;
        }
      }
    }

    .el-tabs__active-bar {
      background: #E50A33;
    }

    .nav_right_box {
      .nav_right_item {
        width: 106px;
        height: 36px;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #FFFFFF;
        text-align: center;
        line-height: 36px;
        font-size: 16px;
        font-family: SourceRegular;
        font-weight: 400;
        color: #FFFFFF;
        display: inline-block;
        margin-left: 30px;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}
</style>