<template>
  <full-page :options="options" ref="fullpage">
    <div class="section" data-menuanchor="one">
      <div class="banner_box">
        <img class="video" src="@/assets/imgs/banner2.png" alt="">
        <div class="banner_con">
          <p>品牌理念</p>
          <span>Ragana品牌源于对健康生活的品质追求，以“专业、智能、持续、健康、服务”为宗旨，集科学健身+健康饮食、运动于一体<br/>致力于推广科学的新型健身模式，倡导健康的生活方式，引领全民健身运动潮流。</span>
        </div>
      </div>
    </div>
    <div class="section map_box" data-menuanchor="two">
      <div class="section_box flex flex-between">
        <div class="section_left_box flex flex-column flex-left flex-center">
          <p>联系我们</p>
          <span>山西省太原市迎泽西大街信达国际金融中心52层</span>
          <span class="phone">0351-2379580<em>（9:00-21:00）</em></span>
          <span class="email">buff@bloomsupplychain.com</span>
        </div>
      </div>
    </div>
    <div class="section" data-menuanchor="three">
      <div class="section_box">
        <div class="section_code_box flex flex-column flex-center">
          <p>更多方式关注Ragana</p>
          <div class="section_code_con flex">
            <div class="section_code_item_box flex flex-column">
              <img src="@/assets/imgs/code1.png" alt="">
              <span>抖音</span>
            </div>
            <div class="section_code_item_box flex flex-column">
              <img src="@/assets/imgs/code2.png" alt="">
              <span>小红书</span>
            </div>
            <div class="section_code_item_box flex flex-column">
              <img src="@/assets/imgs/code3.png" alt="">
              <span>微博</span>
            </div>
            <div class="section_code_item_box flex flex-column">
              <img src="@/assets/imgs/code4.png" alt="">
              <span>公众号</span>
            </div>
          </div>
        </div>
        <!-- <FooterVue></FooterVue> -->
      </div>
    </div>
    <FooterVue class="section section1" data-menuanchor="four"></FooterVue>
  </full-page>

  <RightCode v-show="show"></RightCode>
</template>

<script setup>
import { reactive, ref, defineEmits,onBeforeUnmount} from 'vue';
import FooterVue from '../components/FooterVue.vue';
import RightCode from '../components/RightCode.vue';
const show=ref(true);
const fullpage = ref();
const changeColor = defineEmits(['changeColor','cNames']);

onBeforeUnmount(()=>{
  changeColor('cNames','')
})

const options = {
  licenseKey: "OPEN-SOURCE-GPLV3-LICENSE",
  anchors: ['one', 'two', 'three','four'],
  // menu: '#menu',
  lockAnchors: true,
  navigation:true,//显示导航
  navigationPosition:'right',
  verticalCentered: false,
  scrollOverflow: false,
  onLeave: (index, nextIndex)=>{
    if(nextIndex.index==3){
      show.value=false
    }else{
      show.value=true
    }
    if(nextIndex.index === 0){
      changeColor('changeColor', '#fff')
      changeColor('cNames','')
    }else{
      changeColor('changeColor', '#000')
      changeColor('cNames','nav_box3')
    }
  },
  afterRender:()=>{
    setTimeout(()=>{
      var href=window.location.href.split('?')[1]
      console.log(href,fullpage.value.api)
      fullpage.value.api.moveTo(href,0);
    },200)
  }
}
</script>

<style lang="scss" scoped>
.fp-overflow{
  height: 100%;
}
.one-footer{
  position: inherit;
}
.banner_box {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;

  .video {
    width: 100%;
    height: auto;
  }
  .banner_con{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    height: 264px;
    p {
      font-size: 88px;
      font-family: SourceBold;
      font-weight: bold;
      color: #000;
      // width: 500px;
      text-align: center;
      margin-top: 0;
      margin-bottom: 34px;
    }
    span{
      font-size: 24px;
      font-family: SourceNormal;
      font-weight: 400; 
      color: #666;
      text-align: center;
      display: block;
    }
  }
}
.section{
  position: relative;
  &.map_box{
    background: url(@/assets/imgs/map.png) no-repeat center;
    background-size: 100%;
    background-color: #fff;
    position: relative;
  }
  &.section1{
    background: none;
  }
  .section_box{
    overflow: hidden;
    height: 100%;
    .section_left_box{
      height: 100vh;
      margin-left: 180px;
      p{
        font-size: 70px;
        font-family: SourceBold;
        font-weight: bold;
        color: #000000;
        margin-top: 0;
        margin-bottom: 40px;
      }
      span{
        font-size: 24px;
        font-family: SourceRegular;
        font-weight: 400;
        color: #333;
        &.phone{
          font-size: 44px;
          font-family: BebasNeue;
          font-weight: 400;
          color: #333333;
          margin-top: 10px;
          margin-bottom: 10px;
          em{
            font-style: normal;
            font-size: 14px;
            font-family: SourceRegular;
          }
        }
        &.email{
          font-size: 24px;
          font-family: SourceRegular;
          font-weight: 400;
          color: #333333;
        }
      }
    }
  }
  .section_code_box{
    background: url(@/assets/imgs/banner3.png) no-repeat center;
    background-size: 100%;
    background-color: #E50A33;
    // height: 540px;
    height: 100vh;
    p{
      font-size: 60px;
      font-family: SourceBold;
      font-weight: bold;
      color: #FFFFFF;
      text-align: center;
      margin-bottom: 30px;
      margin-top: 0;
    }
    .section_code_item_box{
      margin: 0 35px;
      span{
        font-size: 14px;
        font-family: SourceNormal;
        font-weight: 400;
        color: #FFFFFF;
        margin-top: 14px;
      }
    }
  }
}
.fp-right,.fp-watermark{
  display: none !important;
  right: -100% !important;
}
</style>